<template>
  <div class="day-legend item">
    <img src="@/assets/IC_Trabajo.svg" /><span v-if="withTitle">
      {{ title }}
    </span>
    <span class="badge badge-warning notify-badge" v-if="badge">{{ badge }}</span>
  </div>
</template>

<script>
export default {
  props: {
    withTitle: {
      type: Boolean,
      default: true,
    },
    badge: {
      type: [String, Number],
      default: "",
    },
    title: {
      type: String,
      default() {
        return this.$t("pages.permission.working");
      },
    },
  },
};
</script>

<style scoped>
.item {
  position: relative;
  padding-top: 0px;
  display: inline-block;
}
.notify-badge {
  position: absolute;
  top: 15px;
  left: 15px;
  text-align: center;
  border-radius: 10px;
}
</style>
