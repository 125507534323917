import AbsenceConfirmWaitingDayLegend from "./AbsenceConfirmWaitingDayLegend.vue";
import AbsenceUnjustifiedDayLegend from "./AbsenceUnjustifiedDayLegend.vue";
import AbsenceJustifiedDayLegend from "./AbsenceJustifiedDayLegend.vue";
import OffDayLegend from "./OffDayLegend.vue";
import SickDayLegend from "./SickDayLegend.vue";
import WorkingDayLegend from "./WorkingDayLegend.vue";
import VacationConfirmWaitingDayLegend from "./VacationConfirmWaitingDayLegend.vue";
import VacationsApprovedDayLegend from "./VacationsApprovedDayLegend.vue";
import VacationsNeedMeetingDayLegend from "./VacationsNeedMeetingDayLegend.vue";
import VacationsRejectedDayLegend from "./VacationsRejectedDayLegend.vue";
import AbsenceRejectedDayLegend from "./AbsenceRejectedDayLegend.vue";
import OvertimeDayLegend from "./OvertimeDayLegend.vue";
import OvertimeNightLegend from "./OvertimeNightLegend.vue";
import HourPlannedLegend from "./HourPlannedLegend.vue";
import CalendarHolidayLegend from "./CalendarHolidayLegend.vue";
import WorkshiftLegend from "./WorkshiftLegend.vue";

export {
  AbsenceConfirmWaitingDayLegend,
  AbsenceUnjustifiedDayLegend,
  AbsenceJustifiedDayLegend,
  OffDayLegend,
  SickDayLegend,
  WorkingDayLegend,
  VacationConfirmWaitingDayLegend,
  VacationsApprovedDayLegend,
  VacationsNeedMeetingDayLegend,
  VacationsRejectedDayLegend,
  AbsenceRejectedDayLegend,
  OvertimeDayLegend,
  OvertimeNightLegend,
  HourPlannedLegend,
  CalendarHolidayLegend,
  WorkshiftLegend,
};
