<template>
  <div v-bind="$attrs" v-on="$listeners" class="fc-badge">
    <div tabindex="0" data-toggle="popover" data-trigger="hover" :data-content="description">
      {{ $t("pages.permission.calendarHolidayShort") }}
    </div>
    <span v-if="withTitle" class="ml-1">{{ $t("pages.permission.calendarHoliday") }}</span>
  </div>
</template>

<script>
export default {
  props: {
    withTitle: {
      type: Boolean,
      default: false,
    },
    description: {
      type: [String],
      default: "",
    },
  },
  mounted() {
    $('[data-toggle="popover"]').popover();
  },
};
</script>

<style scoped>
.fc-badge {
  background-color: black;
  color: white;
  padding: 2px;
  font-size: 10px;
  width: 30px;
  height: 20px;
}
</style>
